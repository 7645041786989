@import '../../../sass/bootstrap/_functions';
@import '../../../sass/matter/_variables';

.container {
    margin: 2rem auto; }
.searchTag {
    font-size: 1.25rem;
    color: white;
    cursor: pointer;
    &:hover {
        background: $secondary; } }


.selectedTag {
    background: $secondary; }
