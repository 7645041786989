

.navbar-collapse {
    .nav-item {
        a.nav-link {
            position: relative;
            width: auto;
            font-family: $font-family-sans-serif;
            font-weight: 400;
            // font-size: 1.2rem
            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0;
                left: 0;
                background-color: $primary;
                visibility: hidden;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: all 0.3s ease-in-out 0s;
                transition: all 0.3s ease-in-out 0s; }
            &:hover {
                &:before {
                    visibility: visible;
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1); } } } }
    button {
        margin-left: 1rem; } }

$lighterblue: desaturate(lighten($primary,10),10);
// @media(min-width:768px)
//     .navbar
//         padding-left: 4rem
//         padding-right: 4rem
@media(max-width:768px) {
    .navbar {
        position: fixed; }
    .navbar-collapse {
        // background: $primary
        background-color: $primary;
        @include linearGradient($primary, $lighterblue);
        position: fixed;
        right: -100%;
        // right: 0
        display: block !important;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100% !important;
        transition: 0.25s all ease-out;
        &.show {
            // transition: 1s all
            right: 0;
            // width: 80%
 }            // height: 100%
        .navbar-nav {
            margin-top: 5em;
            .nav-item {
                a.nav-link {
                    margin: 0.5em 2em;
                    padding: 0.2em;
                    display: inline-block;
                    width: auto;
                    font-size: 2rem;
                    color: white !important;
                    opacity: 0.8;
                    &:before {
                        background-color: white; }
                    &:hover {
                        color: white; } } } } }
    .collapse:not(.show) {
        display: block !important; }

    .navbar-toggler {
        z-index: 10000;
        position: absolute;
        right: 2em; } }

.anchor {

    display: block;
    position: relative;
    top: -100px;
    visibility: hidden; }



