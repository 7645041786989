@mixin linearGradient($top, $bottom) {
    background: $top;
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom));
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%);
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%);
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%);
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); }

@mixin linear-gradient($top-color, $bottom-color, $otop, $obtm) {
    background: -moz-linear-gradient(top, rgba($top-color, $otop) 0%, rgba($bottom-color, $obtm) 100%);
    background: -webkit-linear-gradient(top, rgba($top-color, $otop) 0%, rgba($bottom-color, $obtm) 100%);
    background: linear-gradient(to bottom, rgba($top-color, $otop) 0%, rgba($bottom-color, $obtm) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{ie-hex-str(rgba($top-color, $obtm))}', endColorstr='#{ie-hex-str(rgba($bottom-color, $otop))}',GradientType=0 ); }



