@import '../../../sass/bootstrap/_functions';
@import '../../../sass/matter/_variables';
.card {
    // max-width: 80%
    // cursor: pointer
    margin: auto;
    align-self: center;
    margin-bottom: 2rem;

    .overlay {
        // background: red
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        transition: .2s all ease-in;
        // border: 0rem solid $primary
        // align-items: flex-end
        // align-content: stretch
        &:hover {
            // border: 1rem solid $primary
            background: transparent; }
        .title {
            // align-self: flex-end
            display: flex;
            // height: 50%
            // justify-content: space-around
            // color: white
 }            // background: green
        .tags {
            font-size: 1.5rem;
            .tag {
                background: $primary; } } } }



