$black: rgba(0,0,0,1);
$trans: rgba(0,0,255,0);
.sendable-card {
    cursor: pointer;
    transition: .2s all ease-in;
    // background: red
    display: block;
    // .card-img-overla
    //     display: block
    //  // @include linear-gradient( black, black, 0, 1)
    // img.card-image-top
    //     opacity: 0.5
    &:hover {
        transform: scale(1.02);
        background: $gray-100; }
    //     // @include linear-gradient( black, black, 0, 0.5)
    //  .card-image
    //      opacity: 0.7

    &.half-height {
        // max-height: 200px
        width: 100%;
        img.card-image-top {
            max-height: 300px; } } }


