

strong {
    font-weight: 700; }

.normal-title {
    font-weight: 300; }


label {
    color: $primary;
    font-size: 1.25rem;
    small {
        font-size: 0.8rem; } }

.serif {
    font-family: $font-family-serif; }
