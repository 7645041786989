.highfive {
  position: absolute;
  max-height: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;

  img {
    margin: auto; }

  &:hover {
    transform: scale(1.125);
    cursor: grabbing; }
  &:active {
    transform: scale(1.5);
    cursor: grabbing; } }

