// RESPONSIVE RESIZING
html {
    font-size: 1rem; }
body {
    min-height: 100vh;
    background: #0D2240;
    background: white; }

div#root {
    min-height: 100vh;
    // height: 100vh
 }    // display: flex

.spinner-container {
    position: fixed;
    z-index: 1031;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .spinner-grow {
        width: 5rem;
        height: 5rem; } }


.row.no-gutters {
    margin-right: 0;
    margin-left: 0;

    & > [class*=" col-"] {}
    & > [class^="col-"] {
        padding-right: 0;
        padding-left: 0; } }
.flex-grow {
    flex: 1 0 auto; }

mark {
    background: red;
    padding: 10px; }

@include media-breakpoint-up(xs) {
    html {
        font-size: 0.75rem;
        section {
            padding-left: 2rem;
            padding-right: 2rem; }

        // .page-wrapper
 } }        //     padding: 0 1rem

@include media-breakpoint-up(sm) {
    html {
        font-size: 1rem;
        section {
            padding-left: 2rem;
            padding-right: 2rem; }
        .navbar {
            padding-left: 3rem;
            padding-right: 3rem; } } }

@include media-breakpoint-up(md) {
    html {
        section {
            padding-left: 4rem;
            padding-right: 4rem; }
        .navbar {
            padding-left: 5rem;
            padding-right: 5rem; } } }
@include media-breakpoint-up(xl) {
    html {
        font-size: 1rem;
        section {
            padding-left: 8rem;
            padding-right: 8rem; }
        .navbar {
            padding-left: 9rem;
            padding-right: 9rem; } } }

html {
    scroll-behavior: smooth;
    height: 100%; }
.is-valigned {
    display: flex;
    align-items: center; }

.navbar-brand {
    cursor: pointer; }

.page-wrapper {
    // display: flex
    // background: white
    min-height: 100vh;
    padding-top: 102px;

    section {
        // &.is-fullheight
        //     height: 100vh
        &.is-top {
            height: 70vh;
            min-height: 600px;
            padding-top: 5rem; } }
    // .normal-title, .title
    //     font-size: 2.5rem
    //     margin: 4rem 0
    //  // span.slim
    //  //     font-weight: 300
    //      // background: red
    // a
    //     border-bottom: 1px dotted $primary

    //     &:hover
    //         text-decoration: none
 }    //         opacity: 0.8



.top-section {
    margin-top: 100px; }

.form-check-label {
    font-size: 0.8rem;
    color: #666; }

.news-row {
    margin: 5rem 0; }

.fade-enter {
    opacity: 0.01; }

.fade-enter-active {
    opacity: 1;
    transition: all 500ms ease-out; }

.fade-exit {
    opacity: 1; }

.fade-exit-active {
    opacity: 0.01;
    transition: all 500ms ease-in; }


.takeover-page {
    nav.navbar,
    footer.page-footer {
        display: none !important; } }

footer {
    section {
        .title {
            margin: 0 0 0.5em 0; } } }

.home-hero {

    .container-fluid {
        position: relative;
        min-height: 80%;
 }        // background: red
    .title {
        font-size: 3rem; } }

.scroll-wrapper {}

// .scroll-container
//  cursor: pointer
//  margin: auto
//  position: absolute
//  bottom: 30px
//  // width: 50px
//  color: $primary
//  padding: 10px
//  background: transparent
//  border: none
//  left: 45%
//  right: 45%
//  display: block
//  height: 100px
//  transition: .2s opacity ease-out
//  width: 10%
//  min-width: 65px
//  // background: red
//  text-align: center
//  p
//      margin: 0
//      transition: .2s margin ease-out
//  .icon
//      font-size: 3rem
//      transition: .2s margin ease-out
//  &:hover
//      opacity: 0.8
//      p
//          margin-bottom: 1rem


form {
    position: relative; }
.loader-takeover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,.6); }

.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite; }

@keyframes spin {
    0% {
        transform: rotate(0deg); }
    100% {
        transform: rotate(360deg); } }


.more-less {
    font-size: 1.2em;
    font-style: normal; }


.accordion {
    .card {
        border: none;
        border-bottom: 1px solid #eaeaea !important;
        margin-bottom: 10px; }
    .card-header {
        background: white;
        h5,
        button {
            text-transform: none;
            font-weight: 300;
            letter-spacing: 0;
            font-size: 1.2em; }
        button {
            padding: 0; } } }


.border-pink {
    border-color: $pink !important; }
.border-blue {
    border-color: $indigo !important; }
.border-yellow {
    border-color: $yellow !important; }

.notprod-banner {
    z-index: 1000000;
    opacity: 0.5;
    position: fixed;
    line-height: 2em;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2em;
    background: darkred;
    text-align: center;
    color: white; }
